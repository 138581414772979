import PropTypes from 'prop-types';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { Stack } from '@mui/system';
import { Avatar, Tooltip } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

import { fTime, fDate, fDateTime } from 'src/utils/format-time';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export function RenderCellBadge({ value }) {
  return (
    <Label variant="soft" color="default">
      {value}
    </Label>
  );
}

RenderCellBadge.propTypes = {
  value: PropTypes.string,
};

export function RenderInviteCode({ params }) {
  const inviteCode = params.row[params.field];

  if (!inviteCode?.code) {
    return null;
  }

  const content = (
    <Label variant="soft" color="default">
      {inviteCode?.code}
    </Label>
  );

  if (inviteCode?.originUser?.email_address) {
    return <Tooltip title={`Invited by ${inviteCode.originUser.email_address}`}>{content}</Tooltip>;
  }

  return content;
}

RenderInviteCode.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
};

export function RenderBillingInfo({ params }) {
  const data = params.row;

  if (data.stripeSubscription) {
    return (
      <Label variant="soft" color="success">
        Stripe: {data.stripeSubscription.status}
      </Label>
    );
  }

  if (data.trialPeriodEnd) {
    const endDate = new Date(data.trialPeriodEnd);
    const now = new Date();

    if (now > endDate) {
      return (
        <Tooltip title={`Trial Phase ended at ${fDateTime(data.trialPeriodEnd)}`} arrow>
          <Label variant="soft" color="default">
            Trial: expired
          </Label>
        </Tooltip>
      );
    }

    const timeRemaining = Math.round((endDate - now) / (1000 * 60 * 60 * 24));

    return (
      <Tooltip title={`Trial Phase ends at ${fDateTime(data.trialPeriodEnd)}`} arrow>
        <Label variant="soft" color="info">
          Trial: {timeRemaining} days left
        </Label>
      </Tooltip>
    );
  }

  return null;
}

RenderBillingInfo.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
};

export function RenderCellEllipsis({ params }) {
  return (
    <span title={params.row[params.field]}>
      <LinesEllipsisLoose text={params.row[params.field]} maxLine="1" lineHeight="16" />
    </span>
  );
}

RenderCellEllipsis.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object,
    field: PropTypes.string,
  }),
};

export function RenderCellDate({ params }) {
  return (
    <ListItemText
      primary={fDate(params.row[params.field])}
      secondary={fTime(params.row[params.field])}
      primaryTypographyProps={{ typography: 'body2', noWrap: true }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        typography: 'caption',
      }}
    />
  );
}

RenderCellDate.propTypes = {
  params: PropTypes.shape({
    field: PropTypes.string,
    row: PropTypes.object,
  }),
};

export function RenderCellUser({ params }) {
  return (
    <Stack direction="row" sx={{ py: 1 }}>
      <Avatar src={params.row.avatar_url} alt={params.row.full_name} sx={{ mr: 2 }} />

      <ListItemText
        primary={params.row.full_name}
        secondary={params.row.email_address}
        primaryTypographyProps={{ typography: 'body2' }}
        secondaryTypographyProps={{
          component: 'span',
          color: 'text.disabled',
        }}
      />
    </Stack>
  );
}

RenderCellUser.propTypes = {
  params: PropTypes.shape({
    field: PropTypes.string,
    row: PropTypes.object,
  }),
};
